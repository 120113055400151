import React, {useState} from 'react'

import './footer.scss';
import { images } from '../../constants';
import { client } from '../../client';

const Footer = () => {
  const [formData, setFormData] = useState({name:"", email:"", message:""})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const {name, email, message} = formData;

  const handleChangeInput = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  }

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: formData.name,
      email: email,
      message: message,
    }

    client.create(contact).then(()=>{
      setLoading(false);
      setIsFormSubmitted(true);
    })
  }

  return (
    <div id="contact">
      <div className='app__flex'>
        <h2 className='head-text'>Get in <span>Contact</span> With me</h2>
      </div>
      
      { !isFormSubmitted ?
        <div className='app__footer-form app__flex'>
          <h2>Send Message</h2>
          <div className='app__flex'>
            <input className='p-text' type="text" placeholder='Name' name="name" value={name} onChange={handleChangeInput} />
          </div>
          <div className='app__flex'>
            <input className='p-text' type="text" placeholder='Email' name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div className='app__flex'>
            <textarea className='p-text'
            placeholder='Message'
            value={message}
            name="message"
            onChange={handleChangeInput}>
            </textarea>
          </div>
          <button className='p-text' onClick={handleSubmit} disabled={loading}>{loading ? "Sending..." : "Send Message"}</button>
        </div>
        : <div>
            <h3 className='head-text success-text'>Thanks for getting <span>in touch!</span></h3>
          </div>
      }
      <div className='app__flex'>
        <div className='app__footer-cards'>
          <div className='app__footer-card'>
            <img src={images.email} alt="email"></img>
            <a href='mailto:londonhmball@gmail.com' className='p-text'>londonhmball@gmail</a>
          </div>
          <div className='app__footer-card'>
            <img src={images.mobile} alt="mobile"></img>
            <a href='tel: +61 (450) 876-704' className='p-text'>+61 (450) 876-704</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer