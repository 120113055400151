import './header.scss';

import {motion} from 'framer-motion'
import {images} from '../../constants';

const scaleVarients = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div id="home" className='app__header app__flex'>
      <motion.div 
        whileInView={{x:[-100,0], opacity: [0,1]}}
        transition={{duration: 1}}
        className="app__header-info"
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <span>👋</span>
            <div style={{marginLeft:20}}>
              <p className='p-text'>Welcome to my</p>
              <h1>Portfolio</h1>
            </div>
          </div>

          <div className='tag-cmp app__flex'>
            <p className='p-text'>Software Engineer</p>
            <p className='p-text'>Frontend Designer</p>
          </div>
        </div>

        <motion.div
          variant={scaleVarients}
          whileInView={scaleVarients.whileInView}
          className="app__header-circles"
        >
          {[images.python, images.cpp, images.react, images.javascript ].map((circle, index) => (
            <div className='circle-cmp app__flex' key={`circle-${index}`}>
                <img src={circle} alt="tech- bubble"></img>
            </div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Header