import React from 'react'

import './navbar.scss'

import { images } from '../../constants'

import {HiMenu, HiX} from "react-icons/hi"
import {motion} from "framer-motion"

import { useState } from 'react'

const Navbar = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <img src={images.logo} alt="logo"></img>
      </div>
      <ul className='app__navbar-links'>
        {["home", "about", "work", "skills", "contact"].map((item) =>(
          <li className="app_flex p-text" key={`link-${item}`}>
            <a href={`#${item}`}>{item}</a>
            <div />
          </li>
        ))}
      </ul>

      <div className='app__navbar-menu'>
        <HiMenu onClick={()=>setToggle(true)}></HiMenu>
        {
          toggle && (
            <motion.div
              whileInView={{x: [300,0]}}
              transition={{duration: 0.85, ease: 'easeOut'}}
            >
                <HiX onClick={() => setToggle(false)}></HiX>
                <ul>
                  {["home", "about", "work", "skills", "contact"].map((item) =>(
                    <li key={item}>
                      <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
                    </li>
                  ))}
                </ul>
            </motion.div>
          )
        }
      </div>
    </nav>
  )
}

export default Navbar