import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { urlFor, client } from "../../client";

import "./about.scss";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
      setLoading(false); // set loading to false once data is fetched
    });
  }, []);

  if (loading)
    return (
      <div className="loading">
        <div className="loading-spinner"></div>
      </div>
    );
  
    
  return (
    <div id="about">
      <h2 className="head-text">About <span>Me</span></h2>
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <div
            className="app__profile-item"
            key={about.title + index}
            style={{
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
            }}
          >
            <div className="text-container">
              <h2 className="bold-text">{about.title}</h2>
              <p className="p-text">{about.description}</p>
            </div>
            <div
              className="img-container"
              style={{
                backgroundImage: `url(${urlFor(about.imgUrl)})`,
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;