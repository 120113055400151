import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import python from '../assets/python.png';
import react from '../assets/react.png';

import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

export default {
  email,
  mobile,
  cpp,
  css,
  html,
  javascript,
  python,
  react,
  circle,
  logo,
};