import React, {useEffect, useState} from 'react'
import {motion} from "framer-motion"
import {AiFillGithub} from "react-icons/ai"

import './skills.scss';

import {Octokit} from "octokit"

const Skills = () => {
  const [user, setUser] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const octokit = new Octokit({
      auth: process.env.REACT_APP_GITHUB_TOKEN
    })
    octokit.request('GET /user', {}).then((tmpUserData) => {
      setUser(tmpUserData)
      octokit.request('GET /user/repos', {}).then((repos) => {
        const languagesObj = {};
        repos.data.forEach((repo) => {
          if(repo.language != null) {
            if (languagesObj[repo.language]) {
              languagesObj[repo.language] += 1;
            } else {
              languagesObj[repo.language] = 1;
            }
          }
        })

        setData(languagesObj)
        setLoading(false)
      })
    })
  }, [])

  const sortedLanguages = Object.entries(data).sort((a, b) => b[1] - a[1]);

  return (
    <div id="skills">
      {console.log(user)}
      <div className='app__flex app__skills'>
        <h2 className='head-text'>My <span>Skills</span></h2>
          { !loading &&
            <div className='app__skills-stat-badge'>
              <div className='app__flex app__skills-stat-card'>   
                <div className='app__skills-img app__flex'>
                  <img src={user.data.avatar_url} alt={user.data.name}></img>
                  <motion.div
                  whileHover={{opacity: [0, 1]}}
                  transition={{duration:0.25, ease: "easeInOut", staggerChildren: 0.5}}
                  className="app__skills-hover app__flex">
                    <a href={user.data.html_url} target="_blank" rel="noreferrer">
                      <motion.div
                    whileInView={{scale: [0,1]}}
                    whileHover={{opacity: [1, 0.9]}}
                    transition={{duration:0.25}}
                    className="app__flex">
                        <AiFillGithub></AiFillGithub>
                      </motion.div>
                    </a>
                  </motion.div>
                </div>
                <p className='p-text'>@{user.data.login}</p>
              </div>
              <div className='app__skills-lang'>
                {sortedLanguages.length ? (
                  <div>
                    <h3>Top Languages</h3>
                    <ul className='app__skills-lang-list'>
                      {sortedLanguages.map((language, index) => (
                        <li key={index} className='app__skills-lang-item'>
                          <span className='app__skills-lang-name'>{language[0]}</span>
                          <span className='app__skills-lang-count'>{language[1]}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>

              <div className='app__skills-statistics'>
                <h3>Statistics</h3>
                <ul>
                  <li>Followed by <b>{user.data.followers}</b> user(s)</li>
                  <li>Following <b>{user.data.following}</b> user(s)</li>
                </ul>
              </div>
            </div>
          }
      </div>
    </div>
  )
}

export default Skills